<template>
    <template v-if="props.simple">
        <CheckItemsSimple :checkItems="reactiveCheckItems" :dataObject="props.dataObject" :itemId="itemId" :stepId="stepId" :sectionId="sectionId" :promotedProcessId="promotedProcessId" inline />
    </template>
    <template v-else-if="isMobile">
        <CheckItemsMobile :checkItems="reactiveCheckItems" :dataObject="props.dataObject" :itemId="itemId" :stepId="stepId" :sectionId="sectionId" :promotedProcessId="promotedProcessId" />
    </template>
    <template v-else>
        <ODataTable :dataObject="props.dataObject" hideMultiselectColumn :hideGridMenu="true" filter-row ref="grid" style="min-height:150px" rowDrag :rowDragOptions="{ field: 'Sort' }" v-bind="$attrs">
            <template #cardheader>
                <!-- HEADER -->
                <div class="ms-2">                    
                    <span class="hstack">
                        {{ $t("Checklist") }}: 
                        <span v-if="!props.idPath" class="checklist-header-text">{{ checklistName === null ? $t('Loading...') : checklistName }}</span>
                        <OChecklistsLookup v-else :bind="onChecklistSelected" :IdPath="props.idPath">                            
                            <span class="checklist-header-text">{{ checklistName === null ? $t('Loading...') : checklistName }}</span>
                            <div v-if="isSettingChecklist" class="spinner-border spinner-border-sm" role="status" style="height: 12px; width: 12px; margin-left: 0.32rem;">
                                <span class="visually-hidden">{{ $t("Loading") }}...</span>
                            </div>
                        </OChecklistsLookup>
                        <template v-if="checklistRevision !== null">, {{ $t("Revision") }}: <span class="checklist-header-text">{{ checklistRevision }}</span></template>
                        <i v-if="checklistProcedures.length > 0 && checklistName != null" @click="showChecklistProcedure" class="ms-2 bi bi-info-circle-fill text-primary" style="cursor: pointer; margin-bottom: -0.1rem;" :title="$t('Click to read checklist procedure')"></i>
                    </span>

                    <div class="ms-2 text-muted">
                        <span class="mx-2" v-if="dsCheckItems_ScopeItem.current.TotalCatAWorkflows != null">
                            PA: {{ dsCheckItems_ScopeItem.current.TotalCatAWorkflows }}
                        </span>

                        <span class="mx-2" v-if="dsCheckItems_ScopeItem.current.TotalNonCatAWorkflows != null">
                            PL: {{ dsCheckItems_ScopeItem.current.TotalNonCatAWorkflows }}
                        </span>             
                    </div>
                </div>

                <div class="dropdown d-inline ms-2">
                    <button class="btn btn-outline-primary btn-sm dropdown-toggle ms-1" type="button" id="dropdownMenuButtonSetCIStatus" data-bs-toggle="dropdown" aria-expanded="false">
                        <div v-if="isBatchUpdatingStatus" class="spinner-border spinner-border-sm" style="0.35rem;" role="status">
                            <span class="visually-hidden">{{ $t('Processing...') }}</span>
                        </div>
                        {{ $t("Set Check ") }}                    
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButtonSetCIStatus">
                        <button :disabled="isBatchUpdatingStatus" class="dropdown-item" @click="batchUpdateCheckedStatus('checked')">{{ $t("Set remaining items as Checked") }}</button> 
                        <button :disabled="isBatchUpdatingStatus" class="dropdown-item" @click="batchUpdateCheckedStatus('N/A')">{{ $t("Set remaining items as N/A") }}</button> 
                        <div class="dropdown-divider"></div>
                        <button :disabled="isBatchUpdatingStatus" class="dropdown-item" @click="batchUpdateCheckedStatus('unchecked')">{{ $t("Set all items as unchecked") }}</button> 
                    </div>
                </div> 

                <div v-if="dsCheckItems_ItemLanguages.current.Name" class="dropdown" style="display:inline">
                    <button class="btn btn-outline-primary btn-sm dropdown-toggle ms-1"  type="button" id="dropdownMenuButtonLanguage" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ dsCheckItems_ItemLanguages.current.Name }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButtonLanguage">
                        <div v-for="item in dsCheckItems_ItemLanguages.data" :key="item.Name">
                            <button @click="changeChecklistLanguage(item.index)" class="dropdown-item">{{ item.Name }}</button>
                        </div>
                    </div>
                </div>

                <div v-if="statistics && (statistics.Section_ID != null || statistics.Step_ID != null)" class="ms-auto pe-2">
                    <span class="ml-3" style="font-size:large;font-weight:600" v-if="!statistics.ItemsComplete && statistics.TotalItems > 0">
                        {{ statistics.CompletedItems }} / {{ statistics.TotalItems }}
                    </span>   

                    <span class="ms-2" style="font-weight:600;display:inline" v-if="statistics.ItemsComplete">
                        <span v-if="statistics.SignaturesComplete && statistics.TotalSignatures>0" style="color:green;"><i class="bi bi-check-circle"></i> {{ $t("Checklist Complete and Signed") }}</span>
                        <span v-if="!statistics.SignaturesComplete && statistics.TotalSignatures>0" style="color:blue;"><i class="bi bi-check-circle "></i> {{ $t("Checklist Complete, awaiting Signatures") }}</span>
                        <span v-if="statistics.TotalSignatures==0" style="color:green;"><i class="bi bi-check-circle "></i> {{ $t("Checklist Complete") }}</span>
                    </span>   
                    
                    <!-- <div class=" form-check form-switch float-end d-none" style="display:inline;width:inherit"> -->        
                        <!-- <input class="form-check-input" style="float:none" id="useMobile" type="checkbox" v-model="useMobile"> -->
                        <!-- <label class="ms-1" for="useMobile"> {{ $t("Execution Mode") }}</label> -->
                    <!-- </div> -->
                </div>
            </template>

            <OColumn field="Item" :headerName='$t("Item")' :headerTitle='$t("Item")' width="80" editable sortable />
            <OColumn field="IsHeader" hide :headerName='$t("H")' :headerTitle='$t("H")' width="30" editable v-slot="{row}">
                <input type="checkbox" v-model="row.IsHeader" :class="row.IsHeader ? 'isHeader' : ''" >
            </OColumn>
            <OColumn field="Description" :headerName="$t('Description / Expected Result')" width="400" editable> 
                <template #default="{ row }">
                    <div style="position:relative;display: flex;flex-flow: column;min-height: 30px;" class="h-100 w-100"> 

                        <!-- Base Language - should be editable -->
                        <OContentEditable class="overflow-wrap" :class="row.IsHeader ? 'isHeader' : ''"  :isEditable="false"
                            style="margin-right:-20px;width:calc(100% - 20px);flex:1" 
                            v-model="row.Description" v-if="isBaseLanguage" />                    
                            
                        <!-- Translated version  - should not be editable -->
                        <div v-else v-for="item in JSON.parse(row.Translations)" style="width:calc(100% - 20px)">
                            <div v-if="item.TranslateTo === dsCheckItems_ItemLanguages.current.TranslateTo" :class="row.IsHeader ? 'isHeader' : ''" >                       
                                {{ item.TranslatedItemDescription }}
                            </div>                        
                        </div>

                        <div class="cornsilk px-1 mt-2"  style="cursor:pointer;background-color: cornsilk;" v-if="row.ContentURL"> Url: <a :href="row.ContentURL" target="_blank" style="overflow-wrap:break-word;">{{ row.ContentURLAlias }}</a></div>	
                        <div class="extraTextInfo mt-2" style="font-style: italic;flex:1" v-if="row.ExtraInformationText">{{ row.ExtraInformationText }}</div>
                        
                        <i style="cursor:pointer;position:absolute;right:0px;top:0px;flex:1" 
                            @click="openContentUrlModal"   
                            v-if="row.index === props.dataObject.current.index" 
                            class="bi bi-box-arrow-up-right float-end"></i>
                        
                        <!-- Activity Set / Property fields -->
                        <a style="font-size:small" href="javascript:void(0)" @click="openCheckItemsPropertiesModal" v-if="row.PropertySet_ID">
                            {{ $t("View Object Properties") }} <div v-if="isOpeningDialog" class="spinner-border spinner-border-sm" style="0.35rem;" role="status"></div>
                        </a> 
                        
                    </div>
                </template>
                <template #editor="{ row }">
                    <div style="position:relative;display: flex;flex-flow: column;" class="h-100 w-100"> 
                        <!-- Base Language - should be editable -->
                        <OContentEditable class="overflow-wrap" :class="row.IsHeader ? 'isHeader' : ''"  
                            style="margin-right:-20px;width:calc(100% - 20px);flex:1"
                            v-model="row.Description" v-if="isBaseLanguage" />                    
                        
                        <!-- Translated version  - should not be editable -->
                        <div v-else v-for="item in JSON.parse(row.Translations)" style="width:calc(100% - 20px)">
                            <div v-if="item.TranslateTo === dsCheckItems_ItemLanguages.current.TranslateTo" :class="row.IsHeader ? 'isHeader' : ''" >                       
                                {{ item.TranslatedItemDescription }}
                            </div>                        
                        </div>

                        <div class="cornsilk px-1 mt-2"  style="cursor:pointer;background-color: cornsilk;" v-if="row.ContentURL"> Url: <a :href="row.ContentURL" target="_blank" style="overflow-wrap:break-word;">{{ row.ContentURLAlias }}</a></div>	
                        <div class="extraTextInfo mt-2" style="font-style: italic;flex:1" v-if="row.ExtraInformationText">{{ row.ExtraInformationText }}</div>
                        
                        <i style="cursor:pointer;position:absolute;right:0px;top:0px;flex:1" 
                            @click="openContentUrlModal"   
                            v-if="row.index === props.dataObject.current.index" 
                            class="bi bi-box-arrow-up-right float-end"></i>
                        
                        <!-- Activity Set / Property fields -->
                        <a style="font-size:small" href="javascript:void(0)" @click="openCheckItemsPropertiesModal" v-if="row.PropertySet_ID">
                            {{ $t("View Object Properties") }} <div v-if="isOpeningDialog" class="spinner-border spinner-border-sm" style="0.35rem;" role="status"></div>
                        </a>                     
                    </div>
                </template>
            </OColumn>
            <OColumn field="StatusText" :headerName="$t('Status')" :headerTitle="$t('Status')" class="text-center" width="110" v-slot="{ row }" sortable>
                <template v-if="!row.IsHeader">
                    <button v-if="row.Status == 0" :class="{ 'ignore-click': currentlyUpdatingCheckStatusId === row.ID || isBatchUpdatingStatus }" @click="setStatus(row, 1).then(() => {$emit('checklistItemStatusChanged', 1)});" class="btn py-0 text-muted">
                        <i class="bi bi-check-lg h4 fw-normal"></i>
                    </button>   
                    <button v-else-if="row.Status == -1" :class="{ 'ignore-click': currentlyUpdatingCheckStatusId === row.ID || isBatchUpdatingStatus }" @click="setStatus(row, 0).then(() => {$emit('checklistItemStatusChanged', 0)});" class="btn py-0 text-muted">
                        <i class="bi bi-app h6 fw-normal"></i>
                    </button>
                    <button v-else-if="row.Status == 1" :class="{ 'ignore-click': currentlyUpdatingCheckStatusId === row.ID || isBatchUpdatingStatus }" @click="setStatus(row, -1).then(() => {$emit('checklistItemStatusChanged', -1)});" class="btn py-0 notapplicable">
                        <span class="h6 fw-normal">{{ $t("N/A") }}</span>
                    </button>   
                    <button v-else @click="setStatus(row, 0)" class="changeItemStatus btn py-0 text-muted">
                        <i class="bi bi-app h6 fw-normal"></i>
                    </button>
                </template>                
            </OColumn>
            <OColumn field="Comments" :headerName="$t('Result / Comments')" width="400" editable>                
                <template #editor="{ row }">
                    <div style="position:relative;display: flex;flex-flow: column;" class="h-100 w-100" v-if="!row.IsHeader">
                        <OContentEditable 
                            :class="row.IsHeader ? 'isHeader' : ''" 
                            @blur="row.save();" 
                            data-grid-editor
                            style="max-height:250px;overflow-y: auto;flex:1" 
                            v-model="row.Comments" 
                            v-if="isBaseLanguage"
                        />

                        <div v-else v-for="item in JSON.parse(row.Translations)">
                            <div v-if="item.TranslateTo === dsCheckItems_ItemLanguages.current.TranslateTo" class="hstack" :class="row.IsHeader ? 'isHeader' : ''">                       
                                {{ item.TranslatedItemComments }} 
                                <button class="btn btn-sm btn-link ms-auto" v-if="props.dataObject.current.index === row.index" @click="openEditCurrentComments" :title="$t('Edit Comment')">[{{ $t("Edit") }}]</button>
                            </div>                         
                        </div>
                            
                        <!-- Attachments -->
                        <div v-if="row.ID && filesForItem(row.ID).length > 0">
                            <div style="font-weight: 600;text-decoration:underline;font-size:smaller" class="text-muted mt-1">{{ $t("Attachments:") }}</div>
                            <div v-for="item in filesForItem(row.ID)">
                                <a target="_blank" :href="`/nt/api/file/download/${dsCheckItems_Attachments.viewName}/${item.PrimKey}/${item.FileName}`">
                                    {{ item.FileName }}
                                </a> <i class="bi bi-x" :data-id="item.ID" style="cursor:pointer" @click="deleteFile(item.ID)"></i>
                            </div>                        
                        </div> 

                        <!-- Urls-->
                        <div v-if="row.ID && urlsForItem[row.ID]?.length > 0">
                            <div style="font-weight: 600;text-decoration:underline;font-size:smaller" class="text-muted mt-1">{{ $t("URLs:") }}</div>
                            <div v-for="item in urlsForItem[row.ID]">
                                <a target="_blank" :href="item.URL">
                                    {{ item.Name ?? item.URL }}
                                </a>  
                            </div>                        
                        </div>
                        
                        <!-- Scope Items created for current Checkitem -->
                        <div v-if="row.ID && scopeItemsForCheckItem(row.ID).length > 0">
                            <div style="font-weight: 600;text-decoration:underline;font-size:smaller" class="text-muted mt-1">{{ $t("Scope Items") }}:</div>
                            <div v-for="item in scopeItemsForCheckItem(row.ID)">
                                <a target="_blank" :class="{'text-decoration-line-through': item.Closed }" :href="`/nt/${item.DetailApp_ID}?ID=${item.Item_ID}`">
                                    {{ item.Item_ID }} | {{ item.Process }} | {{ item.Title }} <span style="font-style: italic;" v-if="item.CatAorB">{{ item.CatAorB }}</span>
                                </a>
                                <i class="bi bi-x" :title="$t('Note: this will not delete the Scope Item itself, only the connection to this Check Item')" style="cursor:pointer" @click="removeScopeItemConnection(item.Item_ID)"></i>
                            </div>
                        </div>
                        
                        <div class="hstack gap-2 pt-1" v-if="!row.isNewRecord && row.index === props.dataObject.current.index">                     
                            <i @click="createNewScopeItem(row.ID)"   
                                :title="$t('Create New Scope Item')"                          
                                class="cursor-pointer ms-auto bi bi-plus-circle-fill "></i>                                                

                            <i @click="openCheckItemsUrls(row.ID)"   
                                :title="$t('Add or remove URLs to Check Item')"                          
                                class="cursor-pointer bi bi-globe "></i>                                                

                            <OFileUploadButton customBtnMarkup :data-object="dsCheckItems_Attachments" multiple="multiple" :beforeUpload="() => onBeforeFileUpload(row.ID)" @uploaded="props.dataObject.refreshRow();">
                                <i class="cursor-pointer bi bi-cloud-arrow-up" :title="$t('Upload File')"></i>                            
                            </OFileUploadButton>
                        </div>
                    </div>
                </template>
                
                <template #default="{ row }">
                    <div style="position:relative;display: flex;flex-flow: column;" class="h-100 w-100" v-if="!row.IsHeader">
                        <OContentEditable 
                            :class="row.IsHeader ? 'isHeader' : ''" 
                            :isEditable="false"
                            style="max-height:250px;overflow-y: auto;flex:1" 
                            v-model="row.Comments" 
                            v-if="isBaseLanguage"
                        />

                        <div v-else v-for="item in JSON.parse(row.Translations)">
                            <div v-if="item.TranslateTo === dsCheckItems_ItemLanguages.current.TranslateTo" class="hstack" :class="row.IsHeader ? 'isHeader' : ''">                       
                                {{ item.TranslatedItemComments }} 
                                <button class="btn btn-sm btn-link ms-auto" v-if="props.dataObject.current.index === row.index" @click="openEditCurrentComments" :title="$t('Edit Comment')">[{{ $t("Edit") }}]</button>
                            </div>                         
                        </div>

                        <div v-if="row.ID && filesForItem(row.ID).length > 0">
                            <div style="font-weight: 600;text-decoration:underline;font-size:smaller" class="text-muted mt-1">{{ $t("Attachments:") }}</div>
                            <div v-for="item in filesForItem(row.ID)">
                                <a target="_blank" :href="`/nt/api/file/download/${dsCheckItems_Attachments.viewName}/${item.PrimKey}/${item.FileName}`">
                                    {{ item.FileName }}
                                </a> <i class="bi bi-x" :data-id="item.ID" style="cursor:pointer" @click="deleteFile(item.ID)"></i>
                            </div>                        
                        </div> 

                        <div v-if="row.ID && urlsForItem[row.ID]?.length > 0">
                            <div style="font-weight: 600;text-decoration:underline;font-size:smaller" class="text-muted mt-1">{{ $t("URLs:") }}</div>
                            <div v-for="item in urlsForItem[row.ID]">
                                <a target="_blank" :href="item.URL">
                                    {{ item.Name ?? item.URL }}
                                </a>  
                            </div>                        
                        </div>
                        
                        <!-- Scope Items created for current Checkitem -->
                        <div v-if="row.ID && scopeItemsForCheckItem(row.ID).length > 0">
                            <div style="font-weight: 600;text-decoration:underline;font-size:smaller" class="text-muted mt-1">{{ $t("Scope Items") }}:</div>
                            <div v-for="item in scopeItemsForCheckItem(row.ID)">
                                <a target="_blank" :class="{'text-decoration-line-through': item.Closed }" :href="`/nt/${item.DetailApp_ID}?ID=${item.Item_ID}`">
                                    {{ item.Item_ID }} | {{ item.Process }} | {{ item.Title }} <span style="font-style: italic;" v-if="item.CatAorB">{{ item.CatAorB }}</span>
                                </a>
                                <i class="bi bi-x" :title="$t('Note: this will not delete the Scope Item itself, only the connection to this Check Item')" style="cursor:pointer" @click="removeScopeItemConnection(item.Item_ID)"></i>
                            </div>
                        </div>
                                            
                        <div class="hstack gap-2 pt-1" v-if="!row.isNewRecord && row.index === props.dataObject.current.index">                     
                            <i @click="createNewScopeItem(row.ID)"   
                                :title="$t('Create New Scope Item')"                          
                                class="cursor-pointer ms-auto bi bi-plus-circle-fill "></i>                                                

                            <i @click="openCheckItemsUrls(row.ID)"   
                                :title="$t('Add or remove URLs to Check Item')"                          
                                class="cursor-pointer bi bi-globe "></i>                                                

                            <OFileUploadButton customBtnMarkup :data-object="dsCheckItems_Attachments" multiple="multiple" :beforeUpload="() => onBeforeFileUpload(row.ID)" @uploaded="props.dataObject.refreshRow();">
                                <i class="cursor-pointer bi bi-cloud-arrow-up" :title="$t('Upload File')"></i>                            
                            </OFileUploadButton>
                        </div>                    
                    </div>
                </template>
            </OColumn>
            <OColumn hide field="ResultCategory" :headerName='$t("Result Category")' width="190" editable v-slot:editor="{row}" >
                <ODataLookup v-if="!row.IsHeader"
                    :bind="sel => { row.ResultCategory = sel.Name; row.ResultCategory_ID = sel.ID; row.save(); }"
                    :data-object="dsResultCategories"                         
                    v-model="row.ResultCategory"
                    disableRecent> 
                    <o-column field="ID" width="90"></o-column> 
                    <o-column field="Name" width="300"></o-column>
                </ODataLookup>
            </OColumn>
            <OColumn hide field="ExtraInformationText" width="200" />
            <OColumn hide field="RiskLevel" width="150" editable v-slot:editor="{row}">
                <ODataLookup v-if="!row.IsHeader"
                    :bind="sel => { row.RiskLevel = sel.Name; row.RiskLevel_ID = sel.ID; row.RiskLevelColor=sel.Color; row.save(); }"
                    :data-object="dsRiskLevels"                         
                    v-model="row.RiskLevel"
                    disableRecent> 
                    <o-column field="Name" width="300"></o-column>
                </ODataLookup>
            </OColumn>
            <OColumn hide v-slot="{row}" field="StatusSet" width="130" >
                <ODatePicker :disabled="true" v-model="row.StatusSet" format="General Date Short Time"></ODatePicker>  
            </OColumn>
            <OColumn hide field="StatusSetBy" width="200"  />
            <OColumn hide field="PeerReview" width="300" v-slot="{row}">
                <i style="position:absolute;cursor:pointer;" @click="row.PeerReview = !row.PeerReview; row.save();" v-if="!row.IsHeader && !row.PeerReview" class="bi bi-square"></i>
                <i style="position:absolute;cursor:pointer" @click="row.PeerReview = !row.PeerReview; row.save();" v-if="!row.IsHeader && row.PeerReview" class="bi bi-check-square"></i>
                <input type="text" v-if="!row.IsHeader" style="margin-left:20px;width:calc(100% - 20px)" v-model="row.PeerReviewComment">
            </OColumn>
        </ODataTable>    

        <ContentURLModal ref="contentUrlModalRef" :checkItemsDataObject="dataObject" />
        <CheckItemsUrlsModal ref="checkItemsUrlsModalRef" :checkItemsDataObject="dataObject" />
        <TranslatedCommentsModal ref="editTranslatedCommentsModalRef" :checkItemsDataObject="dataObject" />
        <ProcedureModal ref="procedureModalRef" />
    </template>
</template>

<script setup>
import CheckItemsUrlsModal from './CheckItems.Modal.Urls.vue';
import ContentURLModal from "./CheckItems.Modal.ContentURL.vue";
import TranslatedCommentsModal from "./CheckItems.Modal.TranslatedComments.vue";
import { ref, reactive, provide } from 'vue';
import { ODataTable}  from 'o365-datagrid';
import { OFileUploadButton } from 'o365-fileupload';
import { isMobile } from "o365-mobile";
import CheckItemsSimple from "./CheckItems.CheckItemsSimple.vue";
import CheckItemsMobile from "./CheckItems.CheckItemsMobile.vue";
import { useCheckItems } from "./CheckItems.Composables.useCheckItems.ts";
import { OChecklistsLookup } from "o365-system-lookups";
import ProcedureModal from "./CheckItems.Modal.Procedure.vue";

console.warn("o365-data-components.CheckItems is deprecated, use scope-library.CheckItems instead");

const props = defineProps({
    dataObject: {
        type: Object,
        required: true,
    },
    itemId: {
        type: Number,
        required: true,
    },
    sectionId: {
        type: Number,
        required: false,
    },
    stepId: {
        type: Number,
        required: false,
    },
    // IdPath to be used in filtering lookups.
    idPath: {
        type: String,
        required: false
    },
    // Will be passed as the 'process-id' query parameter when user clicks on 'Create new Scope Item' icon button on a check item.
    promotedProcessId: {
        type: Number,
        required: false
    },
    simple: {
        type: Boolean,
        required: false,
    },
});

if (props.sectionId == null && props.stepId == null) {
    console.error("sectionId or stepId props need to be set");
} else if (props.sectionId != null && props.stepId != null) {
    console.error("Props must be either sectionId or stepId, but not both");
}

const emit = defineEmits(["checklistSelected", "checklistCleared", "checklistLoaded", "checklistItemStatusChanged"]);

const checkItems = useCheckItems(props);
const reactiveCheckItems = reactive(checkItems);

provide("CheckItems", { checkItems: reactiveCheckItems });

window._checkItems = checkItems;

const {
    isOpeningDialog,
    isBatchUpdatingStatus,
    isSettingChecklist,
    filesForItem,
    scopeItemsForCheckItem,
    urlsForItem,
    isBaseLanguage,
    statistics,
    changeChecklistLanguage,
    batchUpdateCheckedStatus,
    onBeforeFileUpload,
    deleteFile,
    removeScopeItemConnection,
    setStatus,
    checklistName,
    checklistRevision,
    currentlyUpdatingCheckStatusId,
    setChecklist,
    checklistProcedures,
    createNewScopeItem
} = checkItems;

const dsCheckItems_ItemLanguages = $getDataObjectById("dsCheckItems_ItemLanguages");
const dsCheckItems_ScopeItem = $getDataObjectById("dsCheckItems_ScopeItem");
const dsCheckItems_Attachments = $getDataObjectById("dsCheckItems_Attachments");
const dsCheckItems_Statistics = $getDataObjectById("dsCheckItems_Statistics");

const contentUrlModalRef = ref();
const checkItemsUrlsModalRef = ref();
const procedureModalRef = ref();
// const createNewWorkflowSelectObjectModalRef = ref(); // Not implemented yet
const editTranslatedCommentsModalRef = ref();
const checkItemsPropertiesModalRef = ref();  // Not implemented yet

function openCheckItemsPropertiesModal() {
    isOpeningDialog.value = true;
    checkItemsPropertiesModalRef.value?.show().then(() => {
        isOpeningDialog.value = false;
    });        
}

function openEditCurrentComments() {
    editTranslatedCommentsModalRef.value?.show();
}

function openCheckItemsUrls(checkItemId) {
    checkItemsUrlsModalRef.value?.show(checkItemId);
}

function openContentUrlModal() {    
    contentUrlModalRef.value?.show();
}

async function onChecklistSelected(row) {
    await setChecklist(row.ID);
    if (row.ID) {
        emit("checklistSelected", props.sectionId, row.ID);
        dsCheckItems_Statistics.load();
    } else {
        emit("checklistCleared");
    }
}

function showChecklistProcedure() {
    procedureModalRef.value.show(checklistProcedures.value);
}

const dsResultCategories = $getOrCreateDataObject({
    "id": "dsResultCategories",
    "viewName": "atbv_Scope_ActivityItemResultCategories",
    "fields": [
        { "name": "ID"},
        { "name": "Name", "sortOrder": 1, "sortDirection": "asc"},
    ],
    "maxRecords": 25,
    "disableAutoLoad": true,
    "optimisticLocking": false
});
    
const dsRiskLevels = $getOrCreateDataObject({
    "id": "dsRiskLevels",
    "viewName": "atbv_Scope_RiskLevels",
    "fields": [
        { "name": "ID", "sortOrder": 1, "sortDirection": "asc"},
        { "name": "Name"},
        { "name": "Color"},
    ],
    "maxRecords": 25,
    "disableAutoLoad": true,
    // "whereClause" : "MyLanguage=1",
    "optimisticLocking": false
});
</script>

<style scoped>
    .isHeader {
        font-weight: 600;
        font-size: larger;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .overflow-wrap {
        overflow-wrap: break-word;
    }

    .checklist-header-text {
        font-weight: 600;
        margin-left: 0.25rem;
    }
    
    .ignore-click {
        pointer-events: none;
    }
</style>